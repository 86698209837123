const nmdbToggleAccordion = () => {
	const accordionTitle = document.getElementsByClassName('accordion__title');

	if (accordionTitle) {
		[...accordionTitle].forEach(elem => {
			elem.addEventListener('click', function () {
				this.classList.toggle('is-active')
			})
		});
	}
	

	const accordionContent = document.getElementsByClassName('accordion__content');

	if (accordionContent) {
		[...accordionContent].forEach(elem => {
			elem.addEventListener('click', function () {
				const title = this.previousElementSibling;

				title.classList.remove('is-active')
			})
		});
	}
	
}
nmdbToggleAccordion();